<svg
  class="h-4 w-4 rounded mr-2"
  aria-hidden="true"
  xmlns="http://www.w3.org/2000/svg"
  id="flag-icon-css-de"
  viewBox="0 0 512 512"
  ><path fill="#ffce00" d="M0 341.3h512V512H0z" /><path d="M0 0h512v170.7H0z" /><path
    fill="#d00"
    d="M0 170.7h512v170.6H0z"
  /></svg
>
