<script lang="ts">
  import { Dropdown, DropdownItem } from "flowbite-svelte";
  import Usa from "./icons/Usa.svelte";
  import Germany from "./icons/Germany.svelte";
  import Italy from "./icons/Italy.svelte";
  import China from "./icons/China.svelte";
  import Spain from "./icons/Spanish.svelte";
  import { Spa } from "@steeze-ui/material-design-icons";
  let dropdownOpen = false;
  export let setLanguage = (language) => {};
  export let language;
  export let footer = false;

  const countryFlags = {
    English: Usa,
    Deutsch: Germany,
    Italian: Italy,
    Chinese: China,
    Spanish: Spain,
  };
</script>

<button
  id="language-dropdown-menu"
  data-dropdown-toggle="language-dropdown-menu"
  class="{footer
    ? 'w-40 rounded-sm'
    : 'w-full rounded-md'} flex items-center justify-between p-2.5 text-sm text-gray-900 outline-none border border-gray-300 dark:border-gray-500 bg-gray-50 dark:bg-gray-700 dark:text-white focus:ring-emerald-500 focus:border-emerald-500 dark:focus:ring-emerald-500 dark:focus:border-emerald-500"
  type="button"
>
  <div class="flex items-center text-center">
    <svelte:component this={countryFlags[language]} />
    {language}
  </div>
  <svg
    aria-hidden="true"
    class="w-5 h-5 text-gray-500"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    ><path
      fill-rule="evenodd"
      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
      clip-rule="evenodd"
    /></svg
  >
</button>

<Dropdown bind:open={dropdownOpen} placement="bottom-start" triggeredBy="#language-dropdown-menu">
  <DropdownItem
    id="english"
    on:click={() => {
      dropdownOpen = false;
      setLanguage("English");
    }}
  >
    <div
      class="active inline-flex items-center text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
    >
      <Usa />
    </div>
    English
  </DropdownItem>
  <DropdownItem
    id="spanish"
    on:click={() => {
      dropdownOpen = false;
      setLanguage("Spanish");
    }}
  >
    <div
      class="active inline-flex items-center text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
    >
      <Spain />
    </div>
    Spanish
  </DropdownItem>
  <DropdownItem
    acitve={true}
    on:click={() => {
      dropdownOpen = false;
      setLanguage("Deutsch");
    }}
  >
    <div
      class="inline-flex items-center text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
    >
      <Germany />
      Deutsch
    </div>
  </DropdownItem>
  <DropdownItem
    on:click={() => {
      dropdownOpen = false;
      setLanguage("Italian");
    }}
  >
    <div
      class="inline-flex items-center text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
    >
      <Italy />
      Italiano
    </div>
  </DropdownItem>
  <DropdownItem
    on:click={() => {
      dropdownOpen = false;
      setLanguage("Chinese");
    }}
  >
    <div
      class="inline-flex items-center text-sm text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
    >
      <China />
      Chinese
    </div>
  </DropdownItem>
</Dropdown>
